import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import Answers from './answers'
import Creatives from './creatives'
import Sites from './sites'
import AnimateHeight from 'react-animate-height';
import amostraLegenda from './amostraLegenda'
import { getReport } from '../../../../../actions/aiActions'
import { translate } from '../../../../../components/Translate';

function shadeColor(color, percent) {

	var R = parseInt(color.substring(1, 3), 16);
	var G = parseInt(color.substring(3, 5), 16);
	var B = parseInt(color.substring(5, 7), 16);

	R = parseInt(R * (100 + percent) / 100);
	G = parseInt(G * (100 + percent) / 100);
	B = parseInt(B * (100 + percent) / 100);

	R = (R < 255) ? R : 255;
	G = (G < 255) ? G : 255;
	B = (B < 255) ? B : 255;

	R = Math.round(R)
	G = Math.round(G)
	B = Math.round(B)

	var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
	var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
	var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

	return "#" + RR + GG + BB;
}

export class SurveyDetail extends Component {

	state = {
		exibir_detalhes: false,
		exibir_ai: false,
		ai_content: '',
	}

	constructor(props) {
		super(props)
		this.state.user = JSON.parse(localStorage.getItem('user'));
	}

	opt_chart = {
		tooltips: false,
		maintainAspectRatio: false,
		scales: {
			yAxes: [{
				display: true,
				ticks: {
					callback: function (label) {
						return label + '%'
					},
					beginAtZero: true,
					min: 0,
					fontColor: '#000'
				},

			}],
		},
		layout: {
			padding: {
				left: 0,
				right: 0,
				top: 10,
				bottom: 20,
			}
		},
		animation: {
			duration: 500,
			onProgress: this.animationChart,
			onComplete: this.animationChart
		},
		legend: {
			display: true,
			labels: {
				fontColor: '#222',
				fontFamily: 'Roboto',
				fontSize: 11,
				filter: function (item, chart) {
					if (item.text.indexOf('Lift') !== 0) {
						return chart;
					}
				},
			},
			onClick: function () {
				return false;
			}
		}
	}


	animationChart() {

		var chartInstance = this.chart,
			ctx = chartInstance.ctx;

		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';

		this.data.datasets.forEach(function (dataset, i) {
			var meta = chartInstance.controller.getDatasetMeta(i);
			meta.data.forEach(function (bar, index) {
				var data = dataset.data[index] + '%';

				ctx.font = "14px Roboto";
				ctx.fillStyle = "#fff";

				let singleUser = JSON.parse(localStorage.getItem('user'))
				if (bar._model.datasetLabel.indexOf('Lift') !== 0) {
					if (bar._model.y > 280) {
						ctx.fillStyle = "#333";
						ctx.fillText(data, bar._model.x, bar._model.y);
						ctx.fillStyle = "#fff";
					} else {
						ctx.fillText(data, bar._model.x, bar._model.y + 30);
					}
				}
				ctx.font = "14px Roboto";
				ctx.fillStyle = "#333";
				if (bar._model.datasetLabel.indexOf('Lift') === 0) {
					ctx.font = "14px Roboto";
					// ctx.fillStyle = "#409444" ;
					ctx.fillStyle = !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'];
					ctx.fillText(bar._model.datasetLabel, bar._model.x, bar._model.y);
					ctx.font = "14px Roboto";
					ctx.fillStyle = "#333";
				} else {
					ctx.fillText(bar._model.datasetLabel, bar._model.x, bar._model.base + 25);
				}
			});

		});

		document.getElementById("chart_survey_" + chartInstance.canvas.id).src = chartInstance.toBase64Image();

	}

	amostra = (amostra) => {
		let nivel = [];
		for (let i = 0; i <= 2; i++) {
			nivel.push(<span key={i} className={amostra <= i ? "ml-1 fa fa-circle text-grey" : "ml-1 fa fa-circle text-success"}></span>)
		}
		return nivel
	}

	preview = (event, url) => {
		event.preventDefault();
		let preview = new URL(url);
		preview.searchParams.append('preview', 'true');
		window.open(preview.href, 'preview', 'width=400,height=300,status=0,location=0,menubar=0,scrollbars=0,resizable=0,titlebar=0')
	}

	exibir_detalhes = () => {
		this.setState({
			exibir_detalhes: true
		})
	}
	ocultar_detalhes = () => {
		this.setState({
			exibir_detalhes: false
		})
	}

	render() {
		const { survey, campaign, index, infoData, singleUser } = this.props;
		const { user } = this.state;


		const chartData = {
			labels: [
				'',
			],
			datasets: [{
				label: translate('Controle'),
				data: [survey.controle.percent],
				// backgroundColor:'#a0b785',
				backgroundColor: !singleUser['colorSecondary'] ? "#83ecbf" : singleUser['colorSecondary'],
				opacity: '0.1',
				barPercentage: (survey.brand_lift ? 1 : 0.5),
			}]
		};
		if (survey.brand_lift) {

			chartData.datasets.push({
				label: 'Lift ' + survey.brand_lift + '%',
				data: [(survey.exposto.percent > survey.controle.percent ? survey.controle.percent : 0)],
				backgroundColor: 'transparent',
				borderColor: !singleUser['colorFeatured'] ? "#83ecbf" : singleUser['colorFeatured'],
				borderWidth: { right: 0, top: 1, left: 0 },
				barPercentage: 1,
			})
		}
		chartData.datasets.push({
			label: translate('Exposto'),
			data: [survey.exposto.percent],
			backgroundColor: !singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured'],
			barPercentage: (survey.brand_lift ? 1 : 0.5),
		})
		// console.log(this.props);
		return (

			<div className="card">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th rowSpan="2">ID</th>
							<th rowSpan="2">{translate('Pergunta')}</th>
							<th rowSpan="2" className="text-center">{translate('Tipo')}</th>
							<th className="text-center" colSpan="3">{translate('Votos')}</th>
							<th rowSpan="2" className="text-center">Lift</th>
							<th rowSpan="2" className="text-center">Benchmark<br /> {survey.type_description}</th>
						</tr>
						<tr>
							<th className="text-center">{translate('Controle')}</th>
							<th className="text-center">{translate('Exposto')}</th>
							<th className="text-center">{translate('Total')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="align-middle"><strong>{survey.ID}</strong></td>
							<td className="align-middle"><strong>{survey.description}</strong>
								{/* <a href={campaign.surveys[index].url} onClick={(e) => this.preview(e, campaign.surveys[index].url)}><img src="/assets/img/eye.png" alt="Visualizar" height="22" /></a>
                             */}

								<a href={survey.url} onClick={(e) => this.preview(e, survey.url)}>
									<svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" fill={!singleUser['colorFeatured'] ? '#26d988' : singleUser['colorFeatured']} className="bi bi-eye x" viewBox="0 -1 16 15">
										<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
										<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
									</svg></a>

							</td>


							<td className="text-center align-middle">{campaign.campaign_type}</td>
							<td className="text-center align-middle">{survey.controle.total}</td>
							<td className="text-center align-middle">{survey.exposto.total}</td>
							<td className="text-center align-middle">{survey.geral.total}</td>
							<td className="text-center align-middle">{
								(survey.brand_lift ?
									<strong className="text-success h3 strong">{survey.brand_lift}%</strong> :
									'-')}
							</td>
							<td className="text-center align-middle"><strong className="text-success h3 strong">{survey.avg_lift}</strong></td>
						</tr>
					</tbody>
				</table>

				<div className="container-fluid">
					<div className="col-md-10 offset-md-1 d-none d-print-block">
						<img src="" id={"chart_survey_" + survey.ID} className="img-responsive" alt='' />
					</div>
					<div className="col-md-10 offset-md-1 pb-4 d-print-none">
						<Bar id={survey.ID} data={chartData} options={this.opt_chart} height={350} />
					</div>
				</div>

				<div className="border-vertical">
					<table className="table table-bordered">
						<thead>
							<tr className="text-center">
								<th>{translate('Grupo')}</th>
								<th>{translate('Votos Positivos')}</th>
								<th>{translate('Votos Totais')}</th>
								<th>{translate('Results')}</th>
								<th>Lift Pts.</th>
							</tr>
						</thead>

						<tbody>
							<tr>
								<td width="200" className="pl-5">{translate('Exposto')}
									<span className="pull-right" title={amostraLegenda[survey.exposto.amostra]}>{this.amostra(survey.exposto.amostra)}</span>
								</td>
								<td className="text-center">{survey.exposto.positivo}</td>
								<td className="text-center">{survey.exposto.total}</td>
								<td className="text-center">{survey.exposto.percent}%</td>
								<td className="text-center align-middle" rowSpan="2">
									{survey.brand_lift ?
										<div><strong className="text-success h3 strong">{survey.brand_lift}%</strong></div>
										:
										'-'
									}
								</td>
							</tr>
							<tr>
								<td className="pl-5">{translate('Controle')}
									<span className="pull-right" title={amostraLegenda[survey.controle.amostra]}>{this.amostra(survey.controle.amostra)}</span>
								</td>
								<td className="text-center">{survey.controle.positivo}</td>
								<td className="text-center">{survey.controle.total}</td>
								<td className="text-center">{survey.controle.percent}%</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='p-3'>
					<h6 className='text-success'><a href="" onClick={async (e) => {
						e.preventDefault();

						this.setState({
							exibir_ai: !this.state.exibir_ai,
							refresh_ai: false,
							ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>"
						});
						if (!this.state.exibir_ai) {
							survey.refresh = false;
							let response = await getReport(survey);
							this.setState({
								ai_content: response.data.text,
								refresh_ai: (user.group_id === '1' ? true : false)
							});
						}
					}
					} className='d-flex align-items-center gap-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightning-charge" viewBox="0 0 16 16">
							<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41z" />
						</svg> {translate('AI Analysis - Overview')} (BETA) <i className={this.state.exibir_ai ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></a></h6>
					<AnimateHeight duration={1000} height={(this.state.exibir_ai ? 'auto' : 0)} style={{ fontSize: '0.6rem' }}>
						<div dangerouslySetInnerHTML={{ __html: this.state.ai_content }}></div>
						{this.state.refresh_ai ? <button className="btn btn-round btn-success btn-sm hidden-print" onClick={async (e) => {
							e.preventDefault();

							this.setState({
								ai_content: "<p class='text-center'><i class='fa fa-spin fa-refresh'></i></p>",
								refresh_ai: false
							});
							let postData = survey;
							postData.refresh = true;
							let response = await getReport(postData);
							this.setState({
								ai_content: response.data.text,
								refresh_ai: (user.group_id === '1' ? true : false)
							});
						}
						}><i className='fa fa-magic'></i> {translate('Gerar Nova Análise')}</button> : null}
					</AnimateHeight>
				</div>
				<AnimateHeight duration={1000} height={(this.state.exibir_detalhes ? 'auto' : 0)}>

					<div className="pageBreak"></div>
					<Answers survey={survey} controle={survey.controle.percent} />

					{infoData.campaign_types_view_id == 1 ?
						<div>
							<div className="pageBreak"></div>
							<Creatives start={infoData.start} end={infoData.end} survey_id={survey.ID} controle={survey.controle} />
							<div className="pageBreak"></div>
							<Sites start={infoData.start} end={infoData.end} survey_id={survey.ID} controle={survey.controle} />

						</div> : null}

				</AnimateHeight>
				{
					this.state.exibir_detalhes ?
						<p className="text-center hidden-print"><button className="btn btn-round btn-success btn-sm" onClick={() => { this.ocultar_detalhes() }}><i className="fa fa-angle-up"></i> {translate('Ocultar Detalhes')}</button></p>
						:
						<p className="text-center hidden-print"><button className="btn btn-round btn-success btn-sm" onClick={() => { this.exibir_detalhes() }}><i className="fa fa-angle-down"></i> {translate('Exibir Detalhes')}</button></p>
				}


			</div >
		)
	}
}
const mapStateToProps = store => ({
	infoData: store.campaign.current,
	singleUser: JSON.parse(localStorage.getItem('user'))
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetail)
